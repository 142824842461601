import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../Faq/faq.css';

const HomeFaq = ({ Data }) => {
  const [openAccordion, setOpenAccordion] = useState(0);

  const handleAccordionChange = (accordionIndex) => {
    setOpenAccordion(openAccordion === accordionIndex ? null : accordionIndex);
  };

  return (
    <div 
      className='col-lg-11 m-auto col-12' 
      data-aos="fade-up" 
      data-aos-easing="ease-in" 
      data-aos-offset="100" 
      data-aos-duration="500" 
      data-aos-once='true'
    >
      <div 
        className='col-12 float-start accordions' 
        data-aos="fade-up" 
        data-aos-easing="ease-in" 
        data-aos-offset="100" 
        data-aos-duration="500" 
        data-aos-once='true'
      >
        {Data.map((faq, index) => (
          <Accordion
            key={index}
            className='remove-shadow py-3 border-bottom m-0'
            expanded={openAccordion === index}
            onChange={() => handleAccordionChange(index)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>{faq.question}</h3>
            </AccordionSummary>
            <AccordionDetails>
              <p className='pt-3' dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default HomeFaq;
