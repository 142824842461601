import React from 'react';
import noimg from '../../images/noimage.jpg';

const Index = ({ ImagePath, ImageAlt = 'Home and soul images', ImageWidth, ImageHeight, ImageClass }) => {
    const imgSrc = ImagePath || noimg;

    return (
        <img
            src={imgSrc}
            alt={ImageAlt}
            width={ImageWidth}
            height={ImageHeight}
            className={ImageClass}
            loading="lazy"
        />
    );
};

export default Index;