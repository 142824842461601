import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Webcontainer from "../WebContainer/Index";
import BreadCrumb from "../BreadCrumb/Index";
import Contact from "../Contact/Index";
import MediaNavabar from "../Header/MediaNavabar";
import Noimage from "../../images/noimage.jpg";
import Image from "../Image";
import axios from 'axios';
import { BasePath } from '../BasePath/Index';
import Container from '../Container/Index';

// Dummy JSON data
const galleryData = [
  {
    imageUrl: "",
    slug: "launch-event",
    title: "launch event",
    datemonth: "January 2024"
  },
  {
    imageUrl: "",
    slug: "appartment-event",
    title: "Appartment Event",
    datemonth: "February 2024"
  },
  {
    imageUrl: "",
    thumbnailUrl: "",
    slug: "new-year",
    title: "New year",
    datemonth: "March 2024",
    type: "vedio"
  },
  {
    imageUrl: "",
    slug: "https://example.com/thumb1.jpg",
    title: "The Daily News",
    datemonth: "January 2024"
  },
  {
    imageUrl: "",
    slug: "https://example.com/thumb2.jpg",
    title: "The Times",
    datemonth: "February 2024"
  },
  {
    imageUrl: "",
    slug: "https://example.com/thumb3.jpg",
    title: "The Herald",
    datemonth: "March 2024"
  }
];

function slugToTitleCase(slug) {
  // Validate the input to ensure it's a non-empty string.
  if (typeof slug !== 'string' || !slug.trim()) {
    return "";
  }

  // Trim the slug to remove leading/trailing spaces, split by hyphen,
  // capitalize the first letter of each word, and filter out any empty strings (e.g., due to multiple hyphens).
  return slug.trim()
    .split("-")
    .map(word => {
      if (word === '') return ''; // Handles potential empty strings
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .filter(Boolean) // Remove any empty strings from the array.
    .join(" ");
}

const GalleryInsidePage = () => {
  // Use galleryData as filteredData
  const [pageData, setpageData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams()
  // console.log(pageData)
  // Convert slug text to capitalized form with first letter of each word uppercase
  const capitalizedSlug = slug
    ? slug
      .split("-")               // Splits "launch-event" into ["launch", "event"]
      .map((word) =>
        word.charAt(0).toUpperCase() + word.slice(1)  // Capitalizes first letter of each word
      )                         // Transforms to ["Launch", "Event"]
      .join(" ")               // Joins with space: "Launch Event"
    : "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BasePath}/event_listing.php?pro_name=${slug}`
        );
        setpageData(response.data);
        // console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);
  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Container _parentClass={"m-0"}>
        {/* <BreadCrumb pageName={slugToTitleCase(slug)} /> */}
        <div className={`pagebreadcumb mt-2 `}>
          <ul className='d-flex'>
            <li><Link to='/'>Home</Link></li>
            <li><Link to={"/media/adcampaign"}>{"Media"}</Link> </li>
            <li><Link to={"/media/events"}>{"Events"}</Link> </li>
            {<li>{slug}</li>}
          </ul>
        </div>
      </Container >
      <Webcontainer _parentClass={"media mb-0"}>
        <div className="title flex-center col-12 float-start flex-wrap text-center position-relative showTextcont">
          <h1 className="col-12 float-start subTitle">{"Events"}</h1>
          <h2 className="heading bigFont text-black col-12 float-start text-capitalize">
            {slugToTitleCase(slug)}
          </h2>
        </div>
        <div className="row">
          <MediaNavabar />
          <div className="w_70 float-start">
            {!pageData || pageData.length === 0 ? (
              <div className="text-center">No data found</div>
            ) : (
              <div className="lightGallerybox">
                <div className="lg-react-element ">
                  {pageData.map((galData, index) => (
                    <Link key={index} to={`${galData.slug}`}>
                      <div className="galleryimg">
                        <Image
                          ImagePath={galData.imageUrl}
                          ImageAlt={`${galData.title} | ${galData.datemonth}`}
                        />
                      </div>
                      <div className="lightcont">
                        <h4 className="m-0 heading5 fw-600 mb-2">
                          {galData.title}
                        </h4>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Webcontainer>
      <div className="section proDetail">
        <Contact />
      </div>
    </>
  )
}

export default GalleryInsidePage
