import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import TextField from "@mui/material/TextField";
import AlertComponent from "../AlertComponent/index";
import { BasePath } from "../BasePath/Index";
import { useNavigate } from "react-router-dom";

const ContactSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone is required"),
});

const EnquiryForm = ({
  downloadRequested = false,
  setDownloadRequested,
  downloadType = null,
  slug = null,
  fileURL = null,
}) => {
  const [alertProps, setAlertProps] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formData = {
        ...values,
        downloadType: downloadRequested ? downloadType : null,
        slug,
      };

      const response = await axios.post(
        `${BasePath}/contact_submit.php`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        resetForm();

        if (fileURL && downloadRequested) {
          // handleDownload();
          document.getElementById("downloadButton").click();
        }

        setTimeout(() => {
          navigate("/thank-you");
        }, 100);
      } else {
        setAlertProps({
          status: "error",
          message:
            response.data.message || "An error occurred. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setAlertProps({
        status: "error",
        message: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {alertProps && (
        <AlertComponent {...alertProps} onClose={() => setAlertProps(null)} />
      )}
      <div className="col-lg-9 col-12 m-auto">
        <Formik
          initialValues={{ name: "", email: "", phone: "" }}
          validationSchema={ContactSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-lg-4">
                  <Field name="name">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Name"
                        className="modifiedinput"
                        fullWidth
                        required
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-lg-4">
                  <Field name="email">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        className="modifiedinput"
                        fullWidth
                        required
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-lg-4">
                  <Field name="phone">
                    {({ field }) => (
                      <TextField
                        {...field}
                        label="Phone"
                        className="modifiedinput"
                        fullWidth
                        required
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    )}
                  </Field>
                </div>
                <div className="col-12 flex-center mt-5 text-center">
                  <div className="col-lg-3 col-12 flex-center">
                    <button
                      className="secondarybtn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? "Submitting..."
                        : downloadRequested
                          ? "Download"
                          : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <a href={fileURL} id="downloadButton" target="_blank" > Download brochure</a>
    </>
  );
};

export default EnquiryForm;
