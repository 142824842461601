import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { FaPlay, FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { BasePath } from "../BasePath/Index";
import "./GalleryBrandCards.css";
import MediaNavabar from "../Header/MediaNavabar";
import Webcontainer from "../WebContainer/Index";
import Image from "../Image";
import Container from "../Container/Index";
import Contact from "../Contact/Index";

const CustomModal = ({
  open,
  onClose,
  mediaUrl,
  mediaType,
  onNext,
  onPrev,
  description,
  hasMultipleItems, // New prop to control navigation buttons
}) => {
  if (!open) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal">
        <div className="close-btn" onClick={onClose}>
          <IoCloseOutline />
        </div>
        {hasMultipleItems && (

          <div className="nav-btn prev-btn" onClick={onPrev}>
            ❮
          </div>
        )}
        <div className="modal-content">
          {mediaType === "video" ? (
            <video key={mediaUrl} autoPlay controls>
              <source src={mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Image key={mediaUrl} ImagePath={mediaUrl} ImageAlt="Media content" />
          )}
          {/* <div className="lightcont background-white w-auto text-left">
          </div> */}
        </div>
        {hasMultipleItems && (

          <div className="nav-btn next-btn" onClick={onNext}>
            ❯
          </div>
        )}
      </div>
    </div>
  );
};

function slugToTitleCase(slug) {
  // Validate the input to ensure it's a non-empty string.
  if (typeof slug !== 'string' || !slug.trim()) {
    return "";
  }

  // Trim the slug to remove leading/trailing spaces, split by hyphen,
  // capitalize the first letter of each word, and filter out any empty strings (e.g., due to multiple hyphens).
  return slug.trim()
    .split("-")
    .map(word => {
      if (word === '') return ''; // Handles potential empty strings
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .filter(Boolean) // Remove any empty strings from the array.
    .join(" ");
}

export const MediaGallery = () => {
  const { slug, slug2 } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const { pathname } = location;

  const type = useMemo(() => {
    if (pathname.includes('events')) {
      return true;
    } else if (pathname.includes('testimonials')) {
      return false;
    }
    return null; // if neither, you can choose to handle this case as needed
  }, [pathname]);

  // Adjust itemsPerPage based on type
  const itemsPerPage = type ? 9 : 3;

  // Fetch data when 'type', 'slug', or 'slug2' changes
  useEffect(() => {
    // If type is null, we might not have a valid endpoint to fetch from
    if (type === null) {
      setIsLoading(false);
      return;
    }

    // Build the API endpoint based on the type
    const endpoint = type
      ? `/events_details.php?pro_name=${slug}&event_name=${slug2}`
      : `/testimonial_details.php?pro_name=${slug2}`;

    axios
      .get(`${BasePath}${endpoint}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching media data:", error);
        setIsLoading(false);
      });
  }, [type, slug, slug2]);

  console.log("type is", type);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!modalIsOpen) return;
      if (e.key === "Escape") {
        closeModal();
      } else if (e.key === "ArrowLeft") {
        handlePrev();
      } else if (e.key === "ArrowRight") {
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [modalIsOpen, selectedIndex, data]);

  useEffect(() => {
    document.body.style.overflow = modalIsOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalIsOpen]);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedItems = Array.isArray(data) ? data.slice(indexOfFirstItem, indexOfLastItem) : [];

  const openModal = (index) => {
    setSelectedIndex(index + indexOfFirstItem);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedIndex(null);
  };

  const handleNext = () => {
    if (selectedIndex !== null && data.length) {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % data.length);
    }
  };

  const handlePrev = () => {
    if (selectedIndex !== null && data.length) {
      setSelectedIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    }
  };

  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  const capitalizedSlug2 = slug2
    ? slug2.charAt(0).toUpperCase() + slug2.slice(1).replace(/[-_]/g, " ")
    : "";
  const capitalizedSlug = slug
    ? slug.charAt(0).toUpperCase() + slug.slice(1).replace(/[-_]/g, " ")
    : "";

  return (
    <>
      <Container _parentClass={"m-0"}>
        {/* <BreadCrumb pageName={slugToTitleCase(slug)} /> */}
        <div className={`pagebreadcumb mt-2 `}>
          <ul className='d-flex'>
            <li><Link to='/'>Home</Link></li>
            <li><Link to={"/media/adcampaign"}>{"Media"}</Link> </li>
            {type &&
              <li><Link to={"/media/events"}>{"Events"}</Link> </li>
            }
            <li><Link to={`/media/events/${slug}`}>{slugToTitleCase(slug)}</Link> </li>
            {<li>{slugToTitleCase(slug2)}</li>}
          </ul>
        </div>
      </Container >
      <Webcontainer _parentClass={"media mb-0"}>
        {isLoading ? (
          <div className="text-center">Loading testimonials...</div>
        ) : (
          <>
            <div className="title flex-center col-12 float-start flex-wrap text-center position-relative showTextcont">
              <h1 className="col-12 float-start subTitle">{slugToTitleCase(slug)}</h1>
              <h2 className="heading bigFont text-black col-12 float-start text-capitalize">
                {slugToTitleCase(slug2)}
              </h2>
            </div>
            <div className={`d-inline-block col-12 float-start ${type ? "_eventpage" : "_testimonialpage"}`}>
              <MediaNavabar />
              <div className="media-gallery">
                <div className="gallery-grid">
                  {!displayedItems || displayedItems.length === 0 ? (
                    <div className="text-center col-10">No data found</div>
                  ) : (
                    displayedItems.map((item, index) => (
                      <div
                        key={index}
                        className="media-item"
                        onClick={() => openModal(index)}
                      >
                        <Image ImagePath={item.thumbnail} ImageAlt={item.description} ImageClass={` ${type ? "galleryimg" : ""}`} />
                        {item.type === "video" && (
                          <i className="play-icon">
                            <FaPlay />
                          </i>
                        )}
                      </div>
                    ))
                  )}
                  {data.length > itemsPerPage && (
                    <div className="pagination-wrapper">
                      <ReactPaginate
                        previousLabel={<FaAngleLeft />}
                        nextLabel={<FaAngleRight />}
                        onPageChange={handlePageClick}
                        pageCount={totalPages}
                        containerClassName="pagination"
                        pageLinkClassName="page-btn"
                        previousLinkClassName="arrow-btn"
                        nextLinkClassName="arrow-btn"
                        activeLinkClassName="active"
                        forcePage={currentPage - 1}
                      />
                    </div>
                  )}
                </div>
                {selectedIndex !== null && (
                  <CustomModal
                    open={modalIsOpen}
                    onClose={closeModal}
                    mediaUrl={data[selectedIndex].mediaUrl}
                    mediaType={data[selectedIndex].type}
                    description={data[selectedIndex].description}
                    onNext={handleNext}
                    onPrev={handlePrev}
                    hasMultipleItems={data.length > 1}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Webcontainer>
      <div className="section proDetail">
        <Contact />
      </div>
    </>

  );
};

export default MediaGallery;
