import React, { useEffect, useState } from "react";
import Webcontainer from "./component/WebContainer/Index";
import Title from "./component/Title/Index";
import "./component/Gallery/Gallery.css";
import Container from "./component/Container/Index";
import BreadCrumb from "./component/BreadCrumb/Index";
import { useParams } from "react-router-dom";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css"; // <-- Added video CSS for proper video support
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgVideo from 'lightgallery/plugins/video';
import lgZoom from "lightgallery/plugins/zoom";
import { BasePath } from "./component/BasePath/Index";
import Contact from "./component/Contact/Index";
import pageData from "./json/walkThrough.json";
import lgShare from 'lightgallery/plugins/share';
import lgHash from 'lightgallery/plugins/hash';
import lgVdeo from 'lightgallery/plugins/video';
import fjGallery from 'flickr-justified-gallery';
import axios from "axios";

const Construction = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // console.log(pageData.address)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BasePath}/walk_through.php?url=${slug}`
        );
        setPageData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formattedSlug = slug.replace(/-/g, " ");
  const capitalizedSlug = slug
    ? slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    : "";

  return (
    <>
      <Container _parentClass={"m-0"}>
        <BreadCrumb
          pageName={`${slug}`}
          pageChildName={"Walk Through"}
          pageUrl={`/projects/homes/${slug}`}
        />
      </Container>
      <Webcontainer _parentClass={"constructionUpdates"}>
        <Title
          firstHeading={"Walk Through"}
          secondHeading={formattedSlug}
          childClass={"text-uppercase"}
        />

        <div className="col-12 float-start">
          {pageData?.dataVal.length === 0 ? (
            <div className="error-message text-center">
              Walk Through Data not available !
            </div>
          ) : (

            <div className="col-12 float-start">
              {pageData?.dataVal.length === 0 ? (
                <div className="error-message text-center">
                  Construction Images not available!
                </div>
              ) : (
                <LightGallery
                  onInit={() => console.log("LightGallery onInit callback")}
                  speed={500}
                  plugins={[lgVideo, lgThumbnail, lgZoom]}
                >
                  {pageData?.dataVal.map((galData, index) => {
                    // Construct the data-video JSON configuration
                    const videoConfig = JSON.stringify({
                      source: [
                        {
                          src: galData.video_link,
                          type: "video/mp4",
                        },
                      ],
                      attributes: {
                        preload: false,
                        playsinline: true,
                        controls: true,
                      },
                    });

                    return (
                      <a
                        key={index}
                        data-poster={galData.cover}
                        data-video={videoConfig}
                        data-sub-html={`${capitalizedSlug}`}
                      >
                        <div className="galleryimg">
                          <img
                            src={galData.thumbnail}
                            alt={`video of ${capitalizedSlug}`}
                          />
                        </div>
                      </a>
                    );
                  })}
                </LightGallery>
              )}
            </div>
          )}
        </div>
      </Webcontainer >
      <div className="section proDetail">
        <Contact
          Data={pageData.address}
          phone={pageData.phone} // dynamic address
          address={pageData.address}
        />
      </div>
    </>
  );
};

export default Construction;
