import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BreadCrumb from './component/BreadCrumb/Index';
import Container from './component/Container/Index';
import Slider from './component/Slider/Index';
import Counter from './component/CountLoader/Index';
import Overview from './component/Paragraph/Overview';
import Quote from './component/Paragraph/Quote';
import VissionMission from './component/Paragraph/VissionMission';
import Tabs from './component/Tabs/Index';
import Value from './component/Paragraph/Value';
import { BasePath } from './component/BasePath/Index'

const AboutHS = () => {
  const [pageData, setpageData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${BasePath}/about.php`);
          setpageData(response.data);
          console.log(response.data);
        } 
       
        catch (error) {
          setError(error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }, []);
    if (isLoading) {
      return <div className="preloader"></div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Slider Data={pageData.homeBanner} mobHeight={'height_auto'} parentClass={'manageText'} />
            <Container _parentClass="m-0 position-relative">
                <BreadCrumb pageName="Home and Soul" />
            </Container>
            <Overview Data={pageData.overview} />
            <Counter Data={pageData.number} />
            <Quote Data={pageData.overview.quote} />
            <VissionMission Data={pageData.overview.VissionMission}/>
            <Value Data={pageData.overview.VissionMission.our_values} />
            <Tabs galleryData={pageData.gallery}/> 
        </>
    );
};

export default AboutHS;
