import React, { useState, useEffect } from "react";
import axios from "axios";
import Webcontainer from "./component/WebContainer/Index";
import Container from "./component/Container/Index";
import BreadCrumb from "./component/BreadCrumb/Index";
import Gallery from "./component/Gallery/Gallery";
import GalleryBrandCrads from "./component/Gallery/GalleryBrandCrads";
import MediaNavabar from "./component/Header/MediaNavabar";
import "./component/Gallery/Gallery.css";
import { BasePath } from "./component/BasePath/Index";
import { useNavigate, useParams } from "react-router-dom";
import Contact from './component/Contact/Index';

const Media = () => {
  const navigate = useNavigate();
  const [pageData, setpageData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { slug } = useParams();
  // useEffect(() => {
  //   navigate("/media/ad-campaign", { replace: true });
  // }, [navigate]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BasePath}/media_gallery.php?cat=${slug === "ad-campaign" ? "adcampaign" : slug
          }`
        );
        setpageData(response.data);
        console.log(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);
  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const uniqueMonths = [
    ...new Set(
      pageData.map((item) => {
        const date = new Date(item.datemonth);
        return date.toLocaleString("default", { month: "short" }).toUpperCase();
      })
    ),
  ];

  const uniqueYears = [
    ...new Set(
      pageData.map((item) => {
        const date = new Date(item.datemonth);
        return date.getFullYear().toString();
      })
    ),
  ];

  // Convert slug text to capitalized form with first letter of each word uppercase
  const capitalizedSlug = slug
    ? slug
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
    : "";

  const pagename = slug === "adcampaign" ? "Ad Campaigns" : slugToTitleCase(slug)
  return (<>
    <Container _parentClass={"m-0"}>
      <BreadCrumb pageChildName={pagename} pageName={"media"} pageUrl={"/media/adcampaign"} />
    </Container>
    <Webcontainer _parentClass={"media mb-0"}>
      {/* <Title secondHeading={'Press Release'} firstHeading={"Media & News"} /> */}
      <div className="title flex-center col-12 float-start flex-wrap text-center position-relative showTextcont">
        <h1 className="col-12 float-start subTitle">{"Media "}</h1>
        <h2 className="heading bigFont text-black col-12 float-start text-capitalize">
          {slug === "adcampaign" ? "Ad Campaigns" : capitalizedSlug}
        </h2>
      </div>
      <div className="row">
        <MediaNavabar />
        {(slug === "adcampaign" || slug === "pressrelease") && (
          <Gallery
            gallery_data={pageData}
            photoGallery={true}
            photoTabs={true}
            uniqueMonths={uniqueMonths}
            uniqueYears={uniqueYears}
          />
        )}
        {(slug === "testimonials" || slug === "events") && (
          <GalleryBrandCrads slug={slug} />
        )}
      </div>
    </Webcontainer>
    <div className="section proDetail">
      <Contact />
    </div>
  </>

  );
};

export default Media;


function slugToTitleCase(slug) {
  // Validate the input to ensure it's a non-empty string.
  if (typeof slug !== 'string' || !slug.trim()) {
    return "";
  }

  // Trim the slug to remove leading/trailing spaces, split by hyphen,
  // capitalize the first letter of each word, and filter out any empty strings (e.g., due to multiple hyphens).
  return slug.trim()
    .split("-")
    .map(word => {
      if (word === '') return ''; // Handles potential empty strings
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .filter(Boolean) // Remove any empty strings from the array.
    .join(" ");
}