import * as React from "react";
const EventsIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={512}
        height={512}
        x={0}
        y={0}
        viewBox="0 0 512 512"
        style={{
            enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        className=""
        {...props}
    >
        <g>
            <path
                d="M146.166 175.363H37.655a7.533 7.533 0 0 0-7.534 7.534v262.409a7.534 7.534 0 0 0 7.534 7.534h436.69a7.533 7.533 0 0 0 7.534-7.534V182.897a7.534 7.534 0 0 0-7.534-7.534H176.304a7.534 7.534 0 1 0 0 15.068h290.507v247.34H45.189v-247.34h100.977a7.533 7.533 0 0 0 7.534-7.534 7.533 7.533 0 0 0-7.534-7.534z"
                fill="#000000"
                opacity={1}
                data-original="#000000"
                className=""
            />
            <path
                d="M484.374 145.314h-33.169l-164.379-81.19c.374-1.864.573-3.79.573-5.762 0-16.13-13.122-29.253-29.252-29.253s-29.253 13.123-29.253 29.253c0 1.972.199 3.899.573 5.762l-164.379 81.19H27.626C12.393 145.314 0 157.707 0 172.94v282.325c0 15.233 12.393 27.626 27.626 27.626h320.839a7.534 7.534 0 1 0 0-15.068H27.626c-6.924 0-12.557-5.633-12.557-12.557V172.94c0-6.924 5.633-12.557 12.557-12.557H315.36a7.534 7.534 0 1 0 0-15.068H99.116L236.16 77.626c5.365 6.117 13.23 9.99 21.987 9.99s16.622-3.873 21.987-9.99l137.044 67.689h-71.68a7.534 7.534 0 1 0 0 15.068h138.876c6.924 0 12.557 5.633 12.557 12.557v282.325c0 6.924-5.633 12.557-12.557 12.557H378.602a7.534 7.534 0 1 0 0 15.068h105.772c15.233 0 27.626-12.393 27.626-27.626V172.94c0-15.233-12.392-27.626-27.626-27.626zM258.147 72.547c-7.821 0-14.184-6.363-14.184-14.184s6.363-14.184 14.184-14.184 14.184 6.363 14.184 14.184c-.001 7.821-6.364 14.184-14.184 14.184z"
                fill="#000000"
                opacity={1}
                data-original="#000000"
                className=""
            />
            <path
                d="M128.328 275.838a7.534 7.534 0 1 0 0-15.068H95.972a7.533 7.533 0 0 0-7.534 7.534v80.557l.001.023-.001.023a7.534 7.534 0 0 0 7.534 7.534h32.356a7.534 7.534 0 1 0 0-15.068h-24.822V316.14h24.822a7.534 7.534 0 1 0 0-15.068h-24.822V275.84h24.822zM234.118 356.44h32.357a7.534 7.534 0 1 0 0-15.068h-24.823v-25.233h24.823a7.534 7.534 0 1 0 0-15.068h-24.823v-25.232h24.823a7.534 7.534 0 1 0 0-15.068h-32.357a7.534 7.534 0 0 0-7.534 7.534v80.557l.001.023-.001.023a7.533 7.533 0 0 0 7.534 7.532zM145.434 270.326l21.12 75.791c1.72 6.175 7.183 10.323 13.593 10.323 6.409 0 11.872-4.149 13.592-10.323l21.12-75.791a7.534 7.534 0 0 0-14.516-4.045l-20.197 72.479-20.198-72.479a7.533 7.533 0 1 0-14.514 4.045zM304.812 348.86v-51.777l31.206 55.515c1.762 3.133 5.617 4.601 9.019 3.432 3.008-1.033 5.083-3.945 5.083-7.125v-80.602a7.534 7.534 0 1 0-15.068 0v51.823l-31.206-55.515a7.533 7.533 0 0 0-14.102 3.692v80.557a7.534 7.534 0 0 0 7.534 7.534 7.533 7.533 0 0 0 7.534-7.534zM394.312 356.44a7.533 7.533 0 0 0 7.534-7.534v-73.022h18.476a7.534 7.534 0 1 0 0-15.068h-52.02a7.534 7.534 0 1 0 0 15.068h18.476v73.022a7.534 7.534 0 0 0 7.534 7.534z"
                fill="#000000"
                opacity={1}
                data-original="#000000"
                className=""
            />
        </g>
    </svg>
);
export default EventsIcon;
