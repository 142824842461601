import React from "react";
import { NavLink } from "react-router-dom";
import WebContainer from './component/WebContainer/Index'
import { useLocation } from 'react-router-dom';

const Error = () => {
  const location = useLocation(); 
  const isThankYouPage = location.pathname === '/thank-you'; 
  return (
   <WebContainer _parentClass={'col-12 float-start m-0'}>
    {isThankYouPage ? (
    <div className="col-lg-8 col-12 m-auto text-center error">
    <h2>Thank you for your interest!</h2>
  <p>
  A representative from Home & Soul will get in touch with you shortly.   </p>
    <NavLink to="/" className='mt-5 buttons'><span>Back to homepage</span></NavLink>
</div>
        ) : (
          <div className="col-lg-8 col-12 m-auto text-center error">
          <h1>404</h1>
        <h2>WE ARE SORRY, PAGE NOT FOUND!</h2>
        <p>
          THE PAGE YOU ARE LOOKING FOR MIGHT HAVE BEEN REMOVED HAD ITS NAME
          CHANGED OR IS TEMPORARILY UNAVAILABLE.
        </p>
        <NavLink to="/" className='btn-11 buttons'><span>Back to homepage</span></NavLink>
      </div>
        )}
   </WebContainer>
  );
};

export default Error;
