import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TestimonialIcon from '../../images/Svg/TestimonialIcon';
import EventsIcon from '../../images/Svg/EventsIcon';
import NewsIcon from '../../images/Svg/NewsIcon';

const MediaNavabar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className='column3'>
      <ul className='medianavbar'>
        <li className={pathname.includes('/media/adcampaign') ? 'active' : ''}>
          <Link to='/media/adcampaign'>
            <NewsIcon />
            Ad Campaigns</Link>
        </li>
        <li className={pathname.includes('/media/testimonials') ? 'active' : ''}>
          <Link to='/media/testimonials'>
            <TestimonialIcon />
            Testimonials</Link>
        </li>
        <li className={pathname.includes('/media/events') ? 'active' : ''}>
          <Link to='/media/events'>
            <EventsIcon />
            Events</Link>
        </li>

      </ul>
    </div>
  );
};

export default MediaNavabar;
