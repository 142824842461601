import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Slider from "./component/Slider/Index";
import Gallery from "./component/Gallery/Index";
import Contact from "./component/Contact/Index";
import { BasePath } from "./component/BasePath/Index";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import FixedStrip from "./component/Tabs/Tabs";
import Noimage from "./images/noimage.jpg";
import LightGallery from "lightgallery/react";
import lgVideo from "lightgallery/plugins/video";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css"; 
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const ProjectDetail = () => {
  const [projectDetails, setProjectDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const { slug } = useParams();
  const fullpageApiRef = useRef(null);
  const location = window.location.href;
  const pathSegments = location.split("/");
  const BredCat = pathSegments[4];
  const [downloadRequested, setDownloadRequested] = useState(false);
  // const contactRef = useRef(null);
  const [downloadType, setDownloadType] = useState("");
  const [fileURL, setFileURL] = useState("");

  const handleDownloadClick = (type, Url) => {
    setDownloadRequested(true);
    setDownloadType(type);
    setFileURL(Url);
    if (fullpageApiRef.current) {
      fullpageApiRef.current.moveTo(
        document.querySelectorAll(".section").length
      );
    }
  };
  const lightGalleryRef = useRef(null);
  const sanitizeSlug = (slug) => {
    return slug.replace(/-/g, " "); 
  };
  
  const sanitizedSlug = sanitizeSlug(slug);
  function openGalleryById(id) {
    const element = document.getElementById(id);
    if (element) {
      element.click();
    } else {
      console.error("Element with id '" + id + "' not found.");
    }

    const container = document.querySelector('.lg-container');
    if (container) {
      container.classList.add('lg-show', 'lg-show-in');
      console.log("class added. = lg-show', 'lg-show-in");
    } else {
      console.error("Element with class 'lg-container' not found.");
    }
  }

  function handleFileChange(event) {
    console.log("Files selected:", event.target.files);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BasePath}/project_details.php?url=${slug}`
        );
        if (response.data.error) {
          setError(response.data.error);
        } else {
          setProjectDetails(response.data);
          console.log(response.data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (fullpageApiRef.current) {
        if (event.key === "ArrowDown") {
          fullpageApiRef.current.moveSectionDown();
        } else if (event.key === "ArrowUp") {
          fullpageApiRef.current.moveSectionUp();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleTitleClick = (index) => {
    if (fullpageApiRef.current) {
      fullpageApiRef.current.moveTo(index + 1);
      setActiveSection(index);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 767);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sectionTitles = useMemo(
    () =>
      [
        "Overview",
        projectDetails?.section1?.heading,
        projectDetails?.section2?.heading,
        projectDetails?.section3?.heading,
        projectDetails?.section4?.heading,
        projectDetails?.section5?.heading,
        projectDetails?.section6?.heading,
        projectDetails?.section7?.heading,
        projectDetails?.gallery?.length ? "Gallery" : null,
        "Downloads",
        "Contact Us",
      ].filter(Boolean),
    [projectDetails]
  );

  const sliderData = useMemo(
    () =>
      projectDetails?.banner
        ? [
          {
            imagePath: projectDetails.banner.bannerPath,
            logo: projectDetails.banner.logo,
            title: projectDetails.banner.bannerHeading,
            mobimgPath: projectDetails.banner.mbannerPath,
          },
        ]
        : [],
    [projectDetails]
  );

  const renderSection = (section, sectionNumber) => {
    if (!section) return null;
    const { heading, image, description } = section;
    if (!heading || !description) return null;

    const isSection2WithSpecificData =
      sectionNumber === 2 &&
      slug === "the-mall-at-boulevard-walk" &&
      projectDetails?.section2?.heading === "THE MIX" &&
      projectDetails?.section2?.description.length === 1 &&
      projectDetails?.section2?.description[0] === "";
    const isSection2WithSpecificData2 =
      sectionNumber === 3 &&
      slug === "boulevard-walk" &&
      projectDetails?.section3?.heading === "THE MALL" &&
      projectDetails?.section3?.description.length === 1 &&
      projectDetails?.section3?.description[0] === "";
    return (
      <div
        className={`section ${slug === "the-mall-at-boulevard-walk" && isSection2WithSpecificData
          ? "custom-section2-class"
          : slug === "boulevard-walk" && isSection2WithSpecificData2
            ? "custom-section2-class"
            : ""
          }`}
        key={sectionNumber}
      >
        <div className="projectscroll first-stn">
          {(isSection2WithSpecificData2 || isSection2WithSpecificData) ? (
            <a href={section.link} className="d-inline col-12 float-start">
              <img
                src={
                  isMobile ? section.mimage || image || Noimage : image || Noimage
                }
                alt={heading || "home & soul"}
              />
            </a>
          ) : (null)
          }
          <img
            src={
              isMobile ? section.mimage || image || Noimage : image || Noimage
            }
            alt={heading || "home & soul"}
          />
          {description.length === 0 || description[0] === "" ? null : (
            <div className="projectscont">
              <div className="details flex-center">
                <div className="detailHeading">
                  <h2>{heading}</h2>
                </div>
                <div className="detailContent">
                  <p>
                    {description.map((desc, index) => (
                      <span key={index}>{desc}</span>
                    ))}
                  </p>
                </div>

                {section.link && (
                  <div className="detailHeading">
                    <a href={section.link} className="button">Know More</a>
                  </div>
                )}

              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error loading data: {error}</div>;
  }

  return (
    <>
      <FixedStrip
        titles={sectionTitles}
        onClick={handleTitleClick}
        activeIndex={activeSection}
        Slug={slug}
        Category={projectDetails?.category}
        Whitelogo={projectDetails?.banner?.logo2}
        BredCat={BredCat}
      />
      <ReactFullpage
        licenseKey={"YOUR_KEY_HERE"}
        scrollingSpeed={1000}
        keyboardScrolling={true}
        onLeave={(origin, destination, direction) => {
          fullpageApiRef.current = destination.fullpageApi;
          setActiveSection(destination.index);
        }}
        render={({ state, fullpageApi }) => {
          fullpageApiRef.current = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
            
             
              <div className="section">
                <div className="projectscroll first-stn align-items-end d-flex Herosection">
                  <Slider Data={sliderData} mobHeight={"projectSliderwith"} />
                </div>
              </div>

              {renderSection(projectDetails?.section1, 1)}
              {renderSection(projectDetails?.section2, 2)}
              {renderSection(projectDetails?.section3, 3)}
              {renderSection(projectDetails?.section4, 4)}
              {renderSection(projectDetails?.section5, 5)}
              {renderSection(projectDetails?.section6, 6)}
              {renderSection(projectDetails?.section7, 7)}

              {projectDetails?.gallery && projectDetails.gallery.length > 0 && (
                <div className="section projectGallery">
                  <div className="projectscroll d-flex align-items-end flex-wrap">
                    <div className="col-12 float-start proGallery">
                      <div className="title flex-center">
                        <span className="m-0">Gallery</span>
                      </div>
                      <Gallery Data={projectDetails.gallery} />
                    </div>
                  </div>
                </div>
              )}
                 <div className="section">
                <div className="projectscroll d-flex align-items-end flex-wrap downloadSection position-relative">
                  <div className="col-12 float-start downloadTab">
                    <div className="col-12 float-start">
                      <div className="title flex-center col-12 float-start flex-wrap">
                        <span className="col-12 float-start">Quick Links</span>
                        <h3 className="heading bigFont text-black col-12 float-start">
                          Downloads
                        </h3>
                      </div>
                      <div className="col-12 float-start quickTabs flex-center gap-25 flex-wrap">
                        {projectDetails.brochure !== "" && (
                          <a
                            onClick={() =>
                              handleDownloadClick(
                                "brochure",
                                projectDetails.brochure
                              )
                            }
                          >
                            <span>Brochure</span>
                          </a>
                        )}
                        {projectDetails.floor_plans !== "" && (
                          <a
                            onClick={() =>
                              handleDownloadClick(
                                "floor_plans",
                                projectDetails.floor_plans
                              )
                            }
                          >
                            <span>Floor Plans</span>
                          </a>
                        )}
                        {projectDetails.construction_link ? (
                          <Link
                            to={`${projectDetails.construction_link}`}
                            state={{ cat: projectDetails.heading }}
                          >
                            <span>Construction Updates</span>
                          </Link>
                        ) : (
                          <Link
                            to={`/construction/${slug}`}
                            state={{ cat: projectDetails.heading }}
                          >
                            <span>Construction Updates</span>
                          </Link>
                        )}
                           <LightGallery
                        onInit={(ref) => {
                          lightGalleryRef.current = ref.instance;
                        }}
                        speed={500}
                        plugins={[lgVideo, lgThumbnail, lgZoom]}
                      >
                        {projectDetails.walkthrough.map((galData, index) => {
                          const videoConfig = JSON.stringify({
                            source: [
                              {
                                src: galData.video_link,
                                type: "video/mp4",
                              },
                            ],
                            attributes: {
                              preload: false,
                              playsinline: true,
                              controls: true,
                            },
                          });

                          const itemId = `gallery-item-${index}`;

                          return (
                            <a
                              key={index}
                              id={itemId}
                              data-video={videoConfig}
                              data-sub-html={`${sanitizedSlug}`}

                            >
                              
                          <span>Walk Through</span>
                          <img
            className="img-responsive d-none"
            src={galData.thumbnail}
          />
                            </a>
                          );
                        })}
                      </LightGallery>
                      </div>
                  
                      {projectDetails.rera_number && (
                        <div className="col-12 float-start quickTabsrera wrap quickTabs">
                          <span>{projectDetails.rera_number}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              </div>
              <div className="section proDetail">
                <Contact
                  Data={projectDetails.address}
                  downloadRequested={downloadRequested}
                  setDownloadRequested={setDownloadRequested}
                  downloadType={downloadType}
                  _slug={slug}
                  phone={projectDetails.phone} 
                  address={projectDetails.address}
                  fileURL={fileURL}
                />
              </div>
            </ReactFullpage.Wrapper >
          );
        }}
      />
        </>
  );
};

export default ProjectDetail;
