import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { BasePath } from "../BasePath/Index";
import "./GalleryBrandCards.css";

const GallerySection = ({ slug }) => {
  const [pageData, setpageData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BasePath}/testimonials_listing.php?type=${slug}`);
        setpageData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [slug]);

  if (isLoading) {
    return <div className="preloader"></div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Filter to show only active items
  const activeItems = pageData.filter((item) => item.isActive);

  return (
    <div className="gallery-container container">
      {/* 
        We use a Bootstrap row with gy-4 (vertical gutter) and gx-4 (horizontal gutter)
        for nice spacing between columns and rows.
      */}
      <div className="row gy-4 gx-4 flex-center">
        {activeItems.map((item) => (
          <div key={item.id} >
            <Link to={item.slug} className="gallery-item-link">
              <div className="gallery-item">
                <div className="logo-container">
                  <img src={item.logo} alt={item.alt} />
                </div>
                <p className="text-underline fw-600 py-0">CLICK HERE</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GallerySection;
