import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Container from '../Container/Index';
import Title from '../../component/Title/Index';
import noimage from '../../images/noimage.jpg';

const isValidUrl = (url) => {
  return url && typeof url === 'string' && url.trim() !== '';
};

const Index = ({ Data }) => {
  const sliderRef = useRef(null);
  const [autoplayEnabled, setAutoplayEnabled] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setAutoplayEnabled(entry.isIntersecting);
      },
      { threshold: 0.3 } 
    );

    if (sliderRef.current) {
      observer.observe(sliderRef.current);
    }

    return () => {
      if (sliderRef.current) {
        observer.unobserve(sliderRef.current);
      }
    };
  }, []);

  if (!Data || !Array.isArray(Data)) {
    return null;
  }

  return (
    <Container _parentClass={'upComingStn'}>
      <Title firstHeading={'IN PLANS'} secondHeading={'WHAT’S NEXT'} />
      <div
        ref={sliderRef}
        className='col-12 float-start'
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-duration="500"
        data-aos-once="true"
        data-aos-easing="ease-in-sine"
      >
        <Swiper
          slidesPerView={2}
          spaceBetween={20}
          loop={true}
          autoplay={autoplayEnabled ? { delay: 6000, disableOnInteraction: false } : false}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="upcomingSwiper"
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 10 }, 
            768: { slidesPerView: 2, spaceBetween: 20 }, 
          }}
        >
          {Data.map((plan) => (
            <SwiperSlide key={plan.id}>
              <div className='Upcomingimg loadeffect col-12 w-100'>
                <img src={isValidUrl(plan.featured_images1) ? plan.featured_images1 : noimage} alt={plan.project_name} />
                <h4>{plan.project_name}</h4>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
};

export default Index;
